<template>
    <div>
        <SvgLine v-if="showMapType===1" ref="svgline" @map-finished="handleMapLoadFinished" :currentCategory="categoryId" @on-click-station="handleClickStation"/>

        <GeoMap v-else ref="geoMap" @map-finished="handleMapLoadFinished" @on-click-station="handleGeoMapClickStation"/>

        <SvgStation ref="svgStation" :stationSvgModal.sync="stationSvgModal" :stationId="chooseStationId" :svgShowType="currentSvgType"
          :assetIds="JSON.stringify(mapShowAssets)" :duration="purchaseDuration" :searchSchedules="searchSchedules" :orderId="orderBean.orderId"
          showStationPortray
          @svg-finished="svgFinished" @on-purchase="handlePurchaseSvgResource"
          @handleChange3dMode="handleBeginRevice3d" />

        <ThreeStation ref="threeStation" @three-finished="threeFinished" @handleChangeSvgMode="handleClickStation" />
    </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import GeoMap from '@/components/map/GeoMap'
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'

import { GetCurrentSchedule } from '@/utils/dateFormat'

import { getProductDetailPageByOrder } from '@/api/order/productdetail'
import { addResource, deleteResource } from '@/api/order/purchase'

export default {
  components: {
    SvgLine, GeoMap, SvgStation, ThreeStation
  },
  data () {
    return {
      showMapType: this.$store.getters.token.publisherSetting.mediaType,
      mapFinished: false,
      stationSvgModal: false, // 弹窗显示SVG站点地图
      chooseStationId: 0,
      searchSchedules: []
    }
  },
  methods: {
    handleMapLoadFinished (val) {
      this.mapFinished = val
    },
    LoadMapData () {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        if (this.mapType === 'flicker') {
          if (this.showMapType === 1) {
            this.$refs.svgline.loadFlickerStations(this.stations)
          } else {
            this.$refs.geoMap.loadFlickerStations(this.stations)
          }
        } else {
          if (this.showMapType === 1) {
            this.$refs.svgline.loadResourceStations(this.stations, 'order')
          } else {
            this.$refs.geoMap.loadResourceStations(this.stations)
          }
        }
      } else {
        setTimeout(() => {
          this.LoadMapData()
        }, 1000)
      }
    },
    hideMapOtherAsset (assetIds) {
      if (this.mapFinished) {
        // 仅限地铁线路图，需要隐藏其他线路
        if (this.showMapType === 1) {
          this.$refs.svgline.hideOtherAsset(assetIds)
        }
      } else {
        setTimeout(() => {
          this.hideMapOtherAsset(assetIds)
        }, 1000)
      }
    },
    handleClickStation (stationId) { // 地图点击站点事件
      this.chooseStationId = stationId
      this.stationSvgModal = true
    },
    handleGeoMapClickStation (stationId) {
      this.$store.commit('set_openmarketorder_update_getMapStationId', stationId)
      this.$store.commit('set_openmarketorder_update_getMapPurchase', new Date())
    },
    svgFinished () { // SVG站点地图加载完毕
      this.$refs.svgStation.initShowBrand()

      const resourceSearchData = {
        orderId: this.orderBean.orderId,
        stationIds: this.chooseStationId,
        schedules: (this.searchSchedules.length > 0) ? JSON.stringify(this.searchSchedules) : null,
        // customskuId: this.customskuId,
        pageSize: 200,
        pageNumber: 1
      }

      getProductDetailPageByOrder(resourceSearchData).then(res => {
        // const resourceList = res.list.map(item => item.resourceId)
        if (this.currentSvgType === 2) {
          this.$refs.svgStation.tagPurchase(res.list)
        } else {
          this.$refs.svgStation.tagResource(res.list)
        }

        this.$refs.svgStation.choseSpinShow()
      })
    },
    threeFinished () { // 3d场景加载完毕
      const resourceSearchData = {
        orderId: this.orderBean.orderId,
        stationIds: this.chooseStationId,
        schedules: (this.searchSchedules.length > 0) ? JSON.stringify(this.searchSchedules) : null,
        // customskuId: this.customskuId,
        pageSize: 200,
        pageNumber: 1
      }

      getProductDetailPageByOrder(resourceSearchData).then(res => {
        this.$refs.threeStation.tagResource(res.list)
      })
    },
    handlePurchaseSvgResource (params) {
      let postData = null

      if (params.isDeleted) {
        const deleteResourcePromise = []
        params.checkSchedules.forEach(item => { // 根据选中的档期，批量删除点位, 后端不支持批量档期删除
          postData = {
            orderId: this.orderBean.orderId,
            deviceId: params.deviceId,
            startDate: item.startDate,
            endDate: item.endDate
          }
          deleteResourcePromise.push(deleteResource(postData))
        })
        Promise.race(deleteResourcePromise).then(res => {
          if (res) {
            // 还原资源颜色
            this.$refs.svgStation.changeResourceColor(params.deviceId, false, params.elementNodeName)
            this.$Notice.success({ desc: '删除资源成功！' })
            // 刷新地图选中的资源数量
            this.handelRefreshMap()
          }
        })
      } else {
        postData = {
          orderId: this.orderBean.orderId,
          deviceId: params.deviceId,
          schedules: JSON.stringify(params.checkSchedules),
          conditions: JSON.stringify(this.searchCondition),
          duration: this.purchaseDuration ? this.purchaseDuration : null
        }
        addResource(postData).then(res => {
          if (res && !res.errcode) {
            // 设置当前选中资源颜色
            this.$refs.svgStation.changeResourceColor(params.deviceId, true, params.elementNodeName)
            this.$Notice.success({ desc: '添加资源成功！' })

            // 刷新地图选中的资源数量
            this.handelRefreshMap()
          }
        })
      }
    },
    handelRefreshMap () {
      // 初始化地图已加载余量，且底部数据未别选中
      if (this.mapType === 'full' && this.bottomProduct === null) {
        this.$store.commit('set_openmarketorder_update_left', new Date())
      }
      this.$store.commit('set_openmarketorder_update_purchase', new Date())
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  },
  computed: {
    categoryId () {
      return this.$store.state.openmarketorder.categoryId
    },
    orderBean () {
      return this.$store.state.openmarketorder.orderBean
    },
    beginUpdateMap () {
      return this.$store.state.openmarketorder.beginUpdateMap
    },
    stations () {
      return this.$store.state.openmarketorder.stations
    },
    mapType () {
      return this.$store.state.openmarketorder.mapType
    },
    searchCondition () {
      return this.$store.state.openmarketorder.searchCondition
    },
    purchaseDuration () {
      return this.$store.state.openmarketorder.purchaseDuration
    },
    orderDefaultSchedules () {
      const orderSchedules = this.orderBean.orderScheduleList

      if (orderSchedules) {
        return orderSchedules.map(x => {
          return {
            startDate: x.startDate,
            endDate: x.endDate,
            label: GetCurrentSchedule(x.startDate, x.endDate)
          }
        })
      } else {
        return []
      }
    },
    checkSchedules () {
      return this.$store.state.openmarketorder.checkSchedules
    },
    currentSvgType () {
      let type = 3
      if (this.$store.state.sys.leftComponent === 'left_OrderEdit') {
        type = 2
      }

      return type
    },
    mapShowAssets () {
      return this.$store.state.openmarketorder.showAssets
    },
    bottomProduct () {
      return this.$store.state.openmarketorder.bottomProduct
    }
  },
  watch: {
    beginUpdateMap (val) {
      this.LoadMapData()
    },
    mapShowAssets (val) {
      if (val.length > 0) {
        this.hideMapOtherAsset(val)
      }
    },
    orderDefaultSchedules (val) {
      this.searchSchedules = val
    },
    checkSchedules (val) {
      this.searchSchedules = val
    }

  }

}
</script>
