// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/marker-sa-h-backgroud.gif");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/marker-sa2-h-background.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".map-container{width:100%}.custom_marker_lable{padding:3px;background-repeat:no-repeat!important;background-position:100%!important;background-origin:content-box!important}.custom_marker_lable_green{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.custom_marker_lable_orange{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.amap-marker-label{position:absolute;z-index:2;border:none;background-color:transparent;white-space:nowrap;cursor:pointer;padding:0;color:#fff;font-size:12px;line-height:15px;top:0!important}", ""]);
// Exports
module.exports = exports;
