<template>
  <div>
    <!-- 地图 -->
    <div ref="geomap" class="map-container" id="gasStationMap"></div>
  </div>
</template>

<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { getStorage } from '@/utils/storage'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      amapObject: null, // 地图对象
      stationMarkers: [], // 站点标记组
      isShowBigMarker: false,
      currentTagStations: [], // 当前需要标注的站点

      defaultMarker: require('@/assets/images/marker-sa.png'),
      defaultBigMarker: require('@/assets/images/marker-sa-h.png'),

      saleMarker: require('@/assets/images/marker-sa2.png'),
      saleBigMarker: require('@/assets/images/marker-h_red.png')
    }
  },
  mounted () {
    document.getElementById('gasStationMap').style.height = (document.body.clientHeight - 50) + 'px'
    this.initMap()
  },
  computed: {
    themeIsDark () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark'
    }
  },
  methods: {
    initMap () {
      const isDongJiao = [27, 29].includes(this.publisherId)
      // 获取地图中心点
      let mapCenter = [104.067923463, 30.6799428454]

      if (isDongJiao) {
        mapCenter = [104.122914, 30.668207]
      } else if (this.publisherId === 3) {
        mapCenter = [120.219375416, 30.2592444615]
      }

      // 获取主题皮肤，设置地图底色
      let mapStyleUrl = ''
      if (this.themeIsDark) {
        mapStyleUrl = 'amap://styles/1f4dd7eedc2e98a369065bc005756706'
      }

      lazyAMapApiLoaderInstance.load().then(() => {
        // 临时使用成都中心点坐标，后续动态设置获取
        const _self = this

        this.amapObject = new AMap.Map('gasStationMap', {
          resizeEnable: true,
          center: new AMap.LngLat(mapCenter[0], mapCenter[1]),
          mapStyle: mapStyleUrl,
          zoom: isDongJiao ? 18 : 12,
          zooms: isDongJiao ? [16, 24] : [10, 16]
        }).on('zoomchange', function (e) {
          _self.handleChangeMapZoom(_self.amapObject.getZoom())
        })

        this.$emit('map-finished', true)
      })
    },
    handleChangeMapZoom (zoom) {
      if ((this.isShowBigMarker && zoom <= 13) || (!this.isShowBigMarker && zoom > 13)) {
        this.clearPageMarker()

        let markerStyle = this.defaultMarker
        this.isShowBigMarker = false
        if (zoom > 13) {
          this.isShowBigMarker = true
          markerStyle = this.defaultBigMarker
        }
        this.loadResourceStations(this.currentTagStations, markerStyle)
      }
    },
    loadResourceStations (stations, markerStyle) {
      const _self = this
      _self.clearPageMarker()

      // 验证地图是否被初始化，否则延迟加载
      if (this.amapObject) {
        this.currentTagStations = stations
        let tagMarker = this.defaultMarker
        if (markerStyle) {
          tagMarker = markerStyle
        } else if (_self.amapObject.getZoom() > 13) {
          // 根据当前的地图大小动态设置图标
          tagMarker = this.defaultBigMarker
        }

        this.stationMarkers = []
        let marker
        let bigMarkerColor = ''
        const _that = this
        // 开始循环添加标注
        stations.forEach(element => {
          if (element.longitude && element.latitude) {
            if (element.inOrder && element.inOrder === 1) { // 在订单内， 需要变更颜色
              tagMarker = _self.amapObject.getZoom() > 13 ? this.saleBigMarker : this.saleMarker
              bigMarkerColor = 'custom_marker_lable_orange'
            } else {
              bigMarkerColor = 'custom_marker_lable_green'
            }

            marker = new AMap.Marker({
              position: new AMap.LngLat(element.longitude, element.latitude),
              offset: new AMap.Pixel(0, 0),
              icon: this.formatStationMarkerIcon(tagMarker), // 添加 Icon 实例
              title: element.stationName,
              topWhenClick: true,
              extData: element.stationId //  站点ID
            }).on('click', function () {
              _that.$emit('on-click-station', element.stationId)
            })

            // 大图需要显示文本
            if (tagMarker !== this.defaultMarker) {
              marker.setLabel({
                content: '<div class=\'custom_marker_lable ' + bigMarkerColor + ' \'>' + element.stationName + '&nbsp;&nbsp;</div>', // 设置文本标注内容
                offset: new AMap.Pixel(-40, -9)
              })
            }

            this.stationMarkers.push(marker)
          }
        })

        this.amapObject.add(this.stationMarkers)
      } else {
        setTimeout(() => {
          _self.markResource(stations, markerStyle)
        }, 1000)
      }
    },
    loadFlickerStations (statonIds) {
      // 此处用于在高德地图中闪烁标记
    },
    tagMarkerSelected (changeStationIds) {
      const selectedMarker = this.amapObject.getZoom() > 13 ? this.saleBigMarker : this.saleMarker
      const greenMarker = this.amapObject.getZoom() > 13 ? this.defaultBigMarker : this.defaultMarker
      let markerContent = null

      this.stationMarkers.forEach(element => {
        markerContent = element.getLabel().content

        // 获取需要变更颜色的所有标注
        if (changeStationIds.includes(element.getExtData())) {
          element.setIcon(this.formatStationMarkerIcon(selectedMarker))
          if (this.amapObject.getZoom() > 13) {
            element.setLabel({
              content: markerContent.replace('custom_marker_lable_green', 'custom_marker_lable_orange'),
              offset: new AMap.Pixel(-40, -9)
            })
          }
        } else {
          // 还原标注颜色
          element.setIcon(this.formatStationMarkerIcon(greenMarker))
          if (this.amapObject.getZoom() > 13) {
            element.setLabel({
              content: markerContent.replace('custom_marker_lable_orange', 'custom_marker_lable_green'),
              offset: new AMap.Pixel(-40, -9)
            })
          }
        }
      })
    },
    replaceMarkerColor (content, color) {
      const newMarkerBackground = 'custom_marker_lable_' + color

      const markerContent = content.replace('custom_marker_lable_orange', 'markerBackgroundTag')
        .replace('custom_marker_lable_green', 'markerBackgroundTag')

      return markerContent.replace('markerBackgroundTag', newMarkerBackground)
    },
    formatStationMarkerIcon (color) {
      let markerIcon = null
      if (color === this.defaultMarker) {
        markerIcon = new AMap.Icon({
          size: new AMap.Size(20, 20), // 图标尺寸
          image: color, // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(20, 20) // 根据所设置的大小拉伸或压缩图片
        })
      } else {
        markerIcon = new AMap.Icon({
          size: new AMap.Size(45, 40), // 图标尺寸
          image: color, // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(33, 30) // 根据所设置的大小拉伸或压缩图片
        })
      }

      return markerIcon
    },
    clearPageMarker () {
      if (this.amapObject) {
        // 清空全部标注
        this.amapObject.remove(this.stationMarkers)
      } else {
        setTimeout(() => {
          this.clearPageMarker()
        }, 1000)
      }
    }
  }
}
</script>

<style>
.map-container {
  width:100%;
}

.custom_marker_lable{
  padding: 3px;
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-origin: content-box !important;
}

.custom_marker_lable_green{
  background: url(../../assets/images/marker-sa-h-backgroud.gif);
}
.custom_marker_lable_orange{
  background: url(../../assets/images/marker-sa2-h-background.gif);
}

.amap-marker-label {
  position: absolute;
  z-index: 2;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  cursor: pointer;
  padding: 0px;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  top: 0px !important;
}
</style>
